
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import CFormInputNumber from "@/components/form/InputNumber.vue";

import { useFilter } from "@/libs/utils";

const VProcedureFilter = defineComponent({
  name: "VProcedureFilter",
  components: { CFormInputText, CFormInputNumber },
  setup() {
    useHead({ title: "Filtrar procedimentos | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const filters = reactive({
      cd_procedimento: route.query.cd_procedimento || undefined,
      nm_procedimento: route.query.nm_procedimento || undefined,
      ds_procedimento: route.query.ds_procedimento || undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      router.replace({ query: { ...route.query, ...filters } });

      loading.submit = true;
      await store.dispatch("searchProcedures", { search: filters, isPrimary: true });
      loading.submit = false;
    }

    function resetFilters() {
      filters.cd_procedimento = undefined;
      filters.nm_procedimento = undefined;
      filters.ds_procedimento = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "procedure-list", query: route.query });
    }

    if (hasAnyFilters.value) applyFilters();

    return { filters, loading, applyFilters, resetFilters, handleClose };
  },
});

export default VProcedureFilter;
